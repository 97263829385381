@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'bootstrap/scss/bootstrap';

@mixin transition {
  transition: all 250ms ease-in-out;
}

.home {
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.bookmark-lists {
  display: flex;
  height: 40px;
  background-image: linear-gradient(to bottom, #222222, #111111);
  padding: 0 16px;

  &-item {
    position: relative;

    &:before {
      content: '';
      height: 2px;
      position: absolute;
      top: 100%;
      width: 100%;
    }

    &-title {
      color: #999;
      height: 40px;
      display: flex;
      align-items: center;
      margin: 0 15px;

      &:not(input) {
        @include transition;
        cursor: default;
        padding: 0 15px;
        margin: 0;
      }

      button {
        margin-right: 4px;
      }
    }
  }
}

.bookmark-lists-item:hover {
  & .bookmark-lists-item-title {
    color: #fff;
    background-color: #111;
  }

  .bookmark-list {
    @include transition;
    opacity: 1;
    visibility: visible;
    background: #fff;
    border-radius: 6px;
  }
}

.bookmark-list {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  padding: 5px 0;

  &:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  &:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
  }
}

.bookmark-link {
  text-decoration: none;
  color: #333;
  white-space: nowrap;
  display: flex;
  padding: 0 20px;
  min-height: 26px;
  align-items: center;

  &:hover {
    color: #fff;
    background-image: linear-gradient(to bottom, #0088cc, #0077b3);
    text-decoration: none;
  }

  &-url {
    display: none;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.3);
  }

  &:hover &-url {
    color: rgba(255, 255, 255, 0.5);
  }

  button {
    width: auto;
  }

  input {
    margin-left: 8px;
  }
}

input {
  background-color: transparent;
  border: none;

  &:focus-visible {
    outline: none;
    text-decoration: underline;
  }
}

.settings-dropdown {
  @include transition;
  opacity: 0;

  &.active,
  &:hover {
    opacity: 1;
  }
}

button {
  width: 100%;
}

.hidden-span {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.bookmark-list-divider {
  height: 2px;
  margin: 9px 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}

.settings-dropdown {
  margin-left: auto;

  .bookmark-list {
    right: 0;

    &:before {
      left: auto;
      right: 9px;
    }

    &:after {
      left: auto;
      right: 10px;
    }
  }
}

.input-file {
  cursor: pointer;
  margin: 0;

  input {
    display: none;
  }
}

.icon-font,
.bookmark-link button {
  font-family: 'Material Icons';
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  &:hover {
    background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  }
}
